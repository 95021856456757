import { useEffect, useState, useRef } from "react";

const LOCAL_STORAGE_KEY = "highlight_overlay_last_run";
const EXPIRATION_DAYS = 30; // Number of days before it can run again

export default function useHighlightOverlay({
                                                selector = ".highlightme",
                                                intervalTime = 1000,
                                                overlayColor = "rgba(0, 0, 0, 0.5)",
                                                zIndexBase = 1000,
                                            } = {}) {
    const [isActive, setIsActive] = useState(false);
    const overlayRef = useRef(null);
    const elementsRef = useRef([]);
    const intervalRef = useRef(null);

    useEffect(() => {
        // ✅ Check local storage to prevent rerunning
        const lastRun = localStorage.getItem(LOCAL_STORAGE_KEY);
        const now = new Date().getTime();
        const expirationTime = EXPIRATION_DAYS * 24 * 60 * 60 * 1000; // Convert days to milliseconds

        if (lastRun && now - parseInt(lastRun, 10) < expirationTime) {
            return; // ✅ If the overlay was recently shown, do nothing
        }

        if (!isActive) return;

        // ✅ Save timestamp in local storage to prevent reruns
        localStorage.setItem(LOCAL_STORAGE_KEY, now.toString());

        // ✅ Disable body scrolling when overlay is active
        document.body.style.overflow = "hidden";

        // Create overlay dynamically
        const overlay = document.createElement("div");
        overlay.style.position = "fixed";
        overlay.style.top = "0";
        overlay.style.left = "0";
        overlay.style.width = "100vw";
        overlay.style.height = "100vh";
        overlay.style.backgroundColor = overlayColor;
        overlay.style.zIndex = `${zIndexBase}`;
        overlay.style.pointerEvents = "auto"; // Allow clicks on overlay
        document.body.appendChild(overlay);
        overlayRef.current = overlay;

        // ✅ Click overlay to stop highlighting and enable scrolling
        overlay.addEventListener("click", () => setIsActive(false));

        // ✅ Find only visible elements
        const allElements = Array.from(document.querySelectorAll(selector));
        let visibleElements = allElements.filter((el) => isElementInViewport(el));

        if (visibleElements.length === 0 && allElements.length > 0) {
            // Scroll to the closest element if none are visible
            scrollToClosestElement(allElements);
            setTimeout(() => {
                visibleElements = allElements.filter((el) => isElementInViewport(el));
                elementsRef.current = visibleElements.length > 0 ? visibleElements : allElements;
                startHighlighting();
            }, 500);
        } else {
            elementsRef.current = visibleElements;
            startHighlighting();
        }

        function startHighlighting() {
            if (elementsRef.current.length === 0) return;

            let index = 0;
            intervalRef.current = setInterval(() => {
                // Reset styles for all elements
                elementsRef.current.forEach((el) => {
                    el.style.zIndex = "auto";
                    el.style.backgroundColor = "";
                    el.classList.remove("highlighted-element");
                    el.style.borderRadius="";
                });

                // Apply styles to the highlighted element
                const currentElement = elementsRef.current[index];
                currentElement.style.zIndex = `${zIndexBase + 10}`;
                currentElement.style.borderRadius="5px";
                flashHighlight(currentElement);
                index = (index + 1) % elementsRef.current.length; // Cycle through visible elements
            }, intervalTime);
        }

        // Cleanup function
        return () => {
            clearInterval(intervalRef.current);

            if (overlayRef.current) {
                overlayRef.current.removeEventListener("click", () => setIsActive(false)); // ✅ Remove event listener
                document.body.removeChild(overlayRef.current);
                overlayRef.current = null;
            }

            // ✅ Restore body scrolling when overlay is removed
            document.body.style.overflow = "";

            // Reset styles when effect is stopped
            elementsRef.current.forEach((el) => {
                el.style.zIndex = "auto";
                el.style.backgroundColor = "";
                el.classList.remove("highlighted-element");
                el.style.borderRadius="";
            });
        };
    }, [isActive, selector, intervalTime, overlayColor, zIndexBase]);

    // ✅ Utility function to check if an element is in the viewport
    function isElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    // ✅ Scroll to the closest element if none are visible
    function scrollToClosestElement(elements, extraScrollOffset = 130) {
        if (elements.length === 0) return;

        const viewportCenterY = window.innerHeight / 2;
        let closestElement = elements[0];
        let minDistance = Math.abs(elements[0].getBoundingClientRect().top - viewportCenterY);

        elements.forEach((el) => {
            const distance = Math.abs(el.getBoundingClientRect().top - viewportCenterY);
            if (distance < minDistance) {
                closestElement = el;
                minDistance = distance;
            }
        });

        // Scroll to bring the element to the center
        closestElement.scrollIntoView({ behavior: "smooth", block: "center" });

        // Add extra scroll adjustment after a slight delay
        setTimeout(() => {
            window.scrollBy({ top: extraScrollOffset, behavior: "smooth" });
        }, 300);
    }


    // ✅ Flash highlight effect
    function flashHighlight(el) {
        el.classList.add("highlighted-element");
        // el.style.transition = "background-color 0.3s ease-in-out";
        el.style.backgroundColor = "rgb(255, 255, 255)";
    }

    return {
        start: () => setIsActive(true),
        stop: () => {
            setIsActive(false);
            elementsRef.current.forEach((el) => el.classList.remove("highlighted-element")); // ✅ Remove all highlighted-element classes
        },
        isActive,
    };
}
